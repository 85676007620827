import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const TitleTypography = styled(Typography)(({ theme }) => ({
  position: 'relative',
  textDecoration: 'none',
  '&:hover': { cursor: 'pointer' },
  '& .arrow': {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '&:hover .arrow': {
    visibility: 'visible',
    opacity: 0.7,
  },
  '&:focus-visible': {
    outline: '3px solid',
    outlineColor: 'hsla(210, 98%, 48%, 0.5)',
    outlineOffset: '3px',
    borderRadius: '8px',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    width: 0,
    height: '1px',
    bottom: 0,
    left: 0,
    backgroundColor: (theme.vars || theme).palette.text.primary,
    opacity: 0.3,
    transition: 'width 0.3s ease, opacity 0.3s ease',
  },
  '&:hover::before': {
    width: '100%',
  },
}));


export default function Latest() {
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);

  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  const [tweets, setTweets] = React.useState([]);
  const [isMore, setIsMore] = React.useState(false)

  const fetchTweets = async (url) =>{
    const apiUrl = url;
    try{
      const res = await fetch(apiUrl);
      const data = await res.json();
      setTweets(data);
    }catch(error){
      console.log('Error fetching data', error);
    }
  };
  // const server = 'http://127.0.0.1:8000'
  const server = 'https://www.economicmessage.com'
  React.useEffect(() => {
    fetchTweets(server+'/api/tweets/');
  },[]);

  const handleClickMore = () => {
    fetchTweets(server+'/api/more/');
    setIsMore(true);
  };

  function utc2local(utc_str){
    const utc = new Date(utc_str);
    let offset = utc.getTimezoneOffset();
    let local = new Date(utc.getTime() - offset * 60000)
    return local.toString()
  };

  return (
    <div>
      <Grid container spacing={1} columns={12} sx={{ my: 4, justifyContent: "center", alignItems: "center" }} >
        {tweets.map((tweet, index) => (
          <Grid key={index} size={{ xs: 12, sm:  11}}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 0,
                height: '100%',
              }}
            >
              <Typography gutterBottom variant="body1" component="div" color='skyblue'>
                {tweet.agency}
              </Typography>
              <TitleTypography
                gutterBottom
                variant="h5"
                onFocus={() => handleFocus(index)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === index ? 'Mui-focused' : ''}
                color='gray'
                style={{whiteSpace: "pre-line"}}
              >
                {tweet.tweet_text+'\n【中文正體】'+tweet.txt_tw}
              </TitleTypography>
              <StyledTypography  gutterBottom variant="caption" component="div" color='darkgreen'>
                {utc2local(tweet.created_at)}
              </StyledTypography >
            </Box>
          </Grid>
        ))}
        {!isMore && <Button variant='contained' color='secondary' onClick={handleClickMore}>查看更多</Button>}
      </Grid>
    </div>
  );
}