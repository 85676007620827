import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import NewsPage from './pages/NewsPage';
import DeclarationPage from './pages/DeclarationPage';
import ChartPage from './pages/ChartPage';
import NotFoundPage from './pages/NotFoundPage';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';

const router = createBrowserRouter([
    {
        path:'/news',
        element:<NewsPage/>,
    },
    {
        path:'/chart',
        element:<ChartPage/>,
    },
    {
        path:'/',
        element:<ChartPage/>,
        errorElement:<NotFoundPage/>,
    },
    {
        path:'/declaration',
        element:<DeclarationPage/>,
    },
    {
        path:'/signup',
        element:<SignUp/>,
    },
    {
        path:'/signin',
        element:<SignIn/>,
    },
]);

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
);
